.sidebar {
    height: 100%;
    transition: width 0.3s;
    padding-top: 100px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar ul li {
    padding: 15px 20px;
    display: flex;
    align-items: center;
  }
  
  .sidebar ul li span {
    margin-left: 10px;
  }
  
  .sidebar.closed ul li span {
    display: none;
  }

  a{
    color: white;
  }
  