@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body{
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-container {
  display: flex;
  height: calc(100vh - 60px); /* Adjust based on navbar height */
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.sidebar-open .sidebar {
  width: 250px;
}

.sidebar-closed .sidebar {
  width: 60px;
}

.sidebar {
  background-color: #2c3e50;
  color: white;
  transition: width 0.3s;
  overflow: hidden;
  height: 100%;
  position: fixed;
  top: 60px; /* Adjust based on navbar height */
  left: 0;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 15px 20px;
  display: flex;
  align-items: center;
}

.sidebar ul li span {
  margin-left: 10px;
  display: inline-block;
}

.sidebar.closed ul li span {
  display: none;
}

.content {
  margin-left: 250px; /* Adjust based on sidebar width */
  flex-grow: 1;
  padding: 20px;
  transition: margin-left 0.3s;
}

.sidebar-closed .content {
  margin-left: 60px; /* Adjust based on sidebar width */
}
