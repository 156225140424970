.collection-container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding-top: 120px;
  }
  
  .collection-container h1 {
    margin-bottom: 20px;
  }
  
  .filters {
    margin-bottom: 20px;
  }

  .collection-container label{
    margin-top: 40px;
  }
  
  .filters input,
  .filters button {
    margin-right: 10px;
    padding: 10px;
    font-size: 16px;
  }

  .filter{
    margin: 5px;
    padding: 10px;
    width: 1200px;
  }
  
  .collections-table {
    width: 100%;
    border-collapse: collapse;
    overflow-y: scroll;
  }
  
  .collections-table th,
  .collections-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .collections-table th {
    background-color: #f2f2f2;
  }
  
  .collections-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .collections-table tr:hover {
    background-color: #ddd;
  }
  
  .collections-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
  }
  

  .accept-button{
    background-color: #4CAF50;
  }
  .accept-button:hover{
    background-color: #31ba35;
  }

  .reject-button{
    background-color: #af4c4c;
  }

  .reject-button:hover{
    background-color: #c81212;
  }


  .contact-admin {
    margin-top: 20px;
    color: #555;
    font-size: 14px;
    background-color: #f9f9f9;
    border-left: 4px solid #f44336;
    padding: 10px;
    border-radius: 5px;
    max-width: 400px;
  }