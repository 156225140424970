.disbursement-container {
  padding: 20px;
  padding-top: 150px;
}

.filters {
  margin-bottom: 20px;
}

.filters input, .filters select {
  margin-right: 10px;
  padding: 8px;
  font-size: 16px;
}

.disbursement-table {
  width: 100%;
  border-collapse: collapse;
}

.disbursement-table th, .disbursement-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.disbursement-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.total-amount {
  text-align: right;
  font-size: 18px;
  padding-top: 15px;
}
