.login-container {
  max-width: 900px;
  margin: 50px auto;
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f9f9f9;
  height: 400px;
  width: 700px;
}

.login-container h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.7rem;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  margin-top: 10px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-top: 10px;
}

button {
  width: 100%;
  padding: 10px;
  background: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

button:hover {
  background: #0056b3;
}

.message {
  margin-top: 20px;
  color: red;
  text-align: center;
}