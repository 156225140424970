.dashboard {
  padding: 50px;
  text-align: center;
  padding-top: 120px;
}

.dashboard h1 {
  margin-bottom: 40px;
  font-size: 2.5em;
  color: #333;
}

.cards {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.card {
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
}

.card h2 {
  font-size: 1.5em;
  color: #666;
}

.card p {
  font-size: 2em;
  color: #000;
  margin: 10px 0 0;
}