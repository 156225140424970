.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c3e50;
    color: white;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 1000;
  }
  
  .menu-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .user-info span {
    margin-right: 10px;
  }
  
  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  