.App {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .main-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }
  
  .sidebar-open .sidebar {
    width: 250px;
  }
  
  .sidebar-closed .sidebar {
    width: 60px;
  }
  
  .sidebar {
    background-color: #2c3e50;
    color: white;
    transition: width 0.3s;
    overflow: hidden;
    height: 100%;
    position: fixed;
    top: 60px; /* Adjust based on navbar height */
    left: 0;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar ul li {
    padding: 15px 20px;
    display: flex;
    align-items: center;
  }
  
  .sidebar ul li span {
    margin-left: 10px;
    display: inline-block;
  }
  
  .content {
    margin-left: 250px; /* Adjust based on sidebar width */
    padding: 20px;
    width: calc(100% - 250px);
    transition: margin-left 0.3s, width 0.3s;
  }
  
  .sidebar-closed .content {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
  
  .navbar {
    height: 60px;
    background-color: #34495e;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  
  .toggle-btn {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  