.branches-page {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    margin-top: 100px;
  }
  
  .branch-form {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"] {
    width: calc(100% - 10px);
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 5px;
    margin: 5px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .branches-list table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .branches-list th, .branches-list td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .branches-list th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .branches-list td {
    vertical-align: middle;
  }
  
  .error-message {
    color: #dc3545;
    font-size: 14px;
    margin-top: 10px;
  }
  