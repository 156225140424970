.loan-requests-page {
    max-width: 1400px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    margin-top: 100px;
  }
  
  .loan-requests-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .loan-requests-table th,
  .loan-requests-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .loan-requests-table th {
    background-color: #f2f2f2;
  }
  
  .error-message {
    color: #dc3545;
    font-size: 14px;
    margin-top: 10px;
  }
  
  button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it appears above other elements */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    text-align: center;
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal button {
    margin-right: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .modal button:last-child {
    background-color: #dc3545;
  }

  .actions button:nth-of-type(1){
    background: green;
  }
  .actions button:nth-of-type(2){
    background: red;
  }
  .actions button:nth-of-type(3){
    background: rgb(127, 19, 127);
  }

  .pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin: 20px 0;
  }
  
  .pagination button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    background: white;
    cursor: pointer;
  }
  
  .pagination button.active {
    background: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .pagination button:disabled {
    background: #f5f5f5;
    cursor: not-allowed;
  }
  
  .pagination button:hover:not(:disabled) {
    background: #f0f0f0;
  }