/* Container with top padding */
.apply-for-loan-page {
  padding-top: 150px;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

/* Header Styling */
.apply-for-loan-page h2 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

/* Form Styling */
.loan-form {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #555;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

.form-group input[type="file"] {
  padding: 0;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #007bff;
  outline: none;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Message Styling */
.message {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message-icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.message p {
  margin: 0;
}

/* Modal Button Styling */
.message button {
  background-color: #28a745;
  border: none;
  padding: 10px 15px;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.message button:hover {
  background-color: #218838;
}
