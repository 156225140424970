/* CollectionReceipt.css */

.collection-receipt-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fbfefc;
  color: #313296;
  font-family: Arial, sans-serif;
  padding-top: 120px;
}

.collection-receipt-container h1 {
  text-align: center;
  color: #313296;
  font-size: 2rem;
  margin-bottom: 20px;
}

.collection-receipt-container .filter-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.collection-receipt-container .filter-section label {
  display: block;
  margin: 10px 0 5px;
  font-size: 1rem;
}

.collection-receipt-container .filter-section select,
.collection-receipt-container .filter-section input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  font-size: 1rem;
}

.collection-receipt-container .filter-section .date-filter {
  display: flex;
  gap: 15px;
}

.collection-receipt-container .filter-section .date-filter input {
  width: 45%;
}

.collection-receipt-container .collection-table {
  margin-top: 20px;
}

.collection-receipt-container .collection-table h2 {
  text-align: center;
  color: #313296;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.collection-receipt-container .collection-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.collection-receipt-container .collection-table table th,
.collection-receipt-container .collection-table table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #d3d3d3;
}

.collection-receipt-container .collection-table table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.collection-receipt-container .collection-table table td {
  background-color: #fff;
}

.collection-receipt-container .print-button {
  display: block;
  width: 200px;
  margin: 0 auto;
  padding: 12px 20px;
  background-color: #313296;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
}

.collection-receipt-container .print-button:hover {
  background-color: #f5a623;
  transition: background-color 0.3s;
}