.field-officer {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  padding-top: 120px;
}

.field-officer h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.field-officer table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.field-officer table th,
.field-officer table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.field-officer table th {
  background-color: #f4f4f4;
}

.field-officer table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.field-officer button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.field-officer button:hover {
  background-color: #0056b3;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  width: 80%;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 80%;
  overflow-y: auto; /* Makes the popup content scrollable */
}

.popup-content h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.popup-content p {
  margin-bottom: 15px;
}

.popup-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.popup-content table th,
.popup-content table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.popup-content table th {
  background-color: #f4f4f4;
}

.popup-content table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.popup-content button {
  display: block;
  margin: 0 auto;
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #a71d2a;
}
